import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'

import { useContext } from 'Context'
import { Login } from './Login'

const Layout = styled.div`
  display: flex;
  grid-gap: .5em;
  flex-direction: column;
`

export const Actions = () => { 
  const [invert, setInvert] = useState(false)
  const buttonRef = useRef(null)
  const { ref: targetRef, ref2: targetRef2 } = useContext()

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.target == targetRef?.current) {
          if (entry.isIntersecting && entry.intersectionRatio > 0) {
            setInvert(true)
          } else {
            setInvert(false)
          }
        }

        if (entry.target == targetRef2?.current) {
          if (entry.isIntersecting && entry.intersectionRatio > 0) {
            setInvert(false)
          } else {
            setInvert(true)
          }
        }
      })
    }, { threshold: [0] })

    if (buttonRef.current && targetRef?.current && targetRef2?.current) {
      observer.observe(targetRef?.current)
      observer.observe(targetRef2?.current)
    }

    return () => {
      if (buttonRef.current && targetRef?.current && targetRef2?.current) {
        observer.unobserve(targetRef?.current)
        observer.unobserve(targetRef2?.current)
      }
    }
  }, [])

  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 900px)").matches
  )

  useEffect(() => {
    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);

  return <Layout ref={buttonRef}>
    <Login invert={invert} variant={matches && 'filled'} />
  </Layout>
}
