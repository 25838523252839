import styled from 'styled-components'
import homeImg from './home.webp'
import { CalendarCheck } from '@styled-icons/bootstrap/CalendarCheck'

import { Em } from 'Em'
import { Text } from 'Text'
import { Block } from 'Block'
import { Button } from 'Button'
import { useContext } from 'Context'

import logo from 'logo.svg'

const Layout = styled(Block)`
  background-color: var(--color-grey);
  background-image: url(${homeImg});
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat; 
  background-attachment: fixed; 
  padding: 0;
`

const HomeBlock = styled.div` 
  width: 35vw;
  height: 100vh;
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 3em;
  align-items: center;
  position: relative;
  z-index: 30;
  background: var(--color-middle-blue);

  @media screen and (width <= 900px) {
    width: 96vw;
    justify-content: center;
    padding-inline: 2vw;
  }
`

const HomeText = styled(Text)`
  font: var(--font-body);
  width: 20vw;

  @media screen and (width <= 900px) {
    width: 100vw;
  }

  text-align: center;
`

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4em;
`

const Logo = styled.img`
  width: 300px;
`

const LogoText = styled.span`
  font: var(--font-logo);
  color: var(--color-white);
  display: flex;
  align-items: center;
  gap: 1em;
`

const LogoSubTitle = styled.span`
  font: var(--font-body);
  color: var(--color-white);
  text-transform: uppercase;
  display: flex;
  justify-content: center;
`

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: var(--color-white);
`

export const Home = () => {
  const { ref } = useContext()

  return <Layout ref={ref}>
    <HomeBlock>
      <Logo src={logo} />
      <LogoText>
        <Dot />
        Evabia
        <Dot />
      </LogoText>
      <LogoSubTitle>
        Conseil & Expertise comptable
      </LogoSubTitle>
      <HomeText data-aos="fade-left">
        <Center>
          <div>
            <Em>Evabia</Em>, votre expert-comptable digitalisé et augmenté.
            En recherche d’un professionnel du conseil pour vous accompagner 
            dans votre business ? Vous êtes au bon endroit !
          </div>
          <div data-aos="fade-left">
            <Button variant="accent" onClick={() => window.open('https://calendly.com/v-bertrand-evabia/rendez-vous-de-30-minutes', '_blank').focus()}>
              <CalendarCheck size={25} />
              Prenez rendez-vous
            </Button>
          </div>
        </Center>
      </HomeText>
    </HomeBlock>
  </Layout>
}
