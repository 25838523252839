import { createGlobalStyle } from 'styled-components'

import GaretRegular from './Garet-Book.ttf'
import Neoneon from './Neoneon.otf'

export const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Garet';
    src: url(${GaretRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neoneon';
    src: url(${Neoneon}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  :root {
    --font-logo: 400 3em / 1 Neoneon;
    --font-body: 400 1em / 1.5 Garet;
    --font-button: 400 1em / 1.5 Garet;
    --font-title: 600 2.5em / 1.2 Garet;
    --font-cta: 600 1.5em / 1.5 Garet;
    --font-small: 400 .9em / 1.5 Garet
  }
`
