import { createGlobalStyle } from 'styled-components'

export const Colors = createGlobalStyle`
  :root {
    --color-blue: #9AEAF5;
    --color-brown: #B59A86;
    --color-orange: #F5AE78;
    --color-pale-blue: #849DA1;
    --color-pale-brown: #75695F;
    --color-grey: #434E4F;
    --color-grey-darken: #212727;
    --color-white: #FFFFFF;
    --color-black: #000000;
    --color-middle-blue: #275d67;
    --color-dark-blue: #1f4951;
  }
`
