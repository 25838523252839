import { Children, useRef, useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'

const Dot = styled.a`
  background: ${({ active }) => active ?  'var(--color-white)' : 'rgba(0,0,0,.3)'};
`

const Slides = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
    /*
    scroll-snap-points-x: repeat(300px);
    scroll-snap-type: mandatory;
    */

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  & > div {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: max-content;
    height: 300px;
    margin-right: 50px;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > div:target {
  /*   transform: scale(0.8); */
  }
`

const Slider = styled.div`
  max-width: 1200px;
  text-align: center;
  overflow: hidden;

  @supports (scroll-snap-type) {
    & > a {
      display: none;
    }
  }

  & ${Dot} {
    display: inline-flex;
    width: 1rem;
    height: 1rem;
    text-decoration: none;
    justify-content: center;
    border-radius: 50%;
    margin: 0 0 0.5rem 0;
    position: relative;

    &:active {
      top: 1px;
    }

    &:focus {
      background: #000;
    }
  }
`

const Controls = styled.div`
  display: flex;
  grid-gap: 1.5em;
  align-items: center;
  justify-content: center;
`

export const Carousel = ({ children, ...props }) => { 
  const items = Children.toArray(children)
  const ids = Array.from({ length: items.length }, (_, index) => index)

  const [visible, endRef] = useVisible()
  const [index, next, setIndex] = useCyclicIndex(items)
  useCycle(visible, index, next)

  return <Slider {...props}>
    <Controls>
      {ids.map(id => <Dot href={`#slide-${id}`} active={index === id} onClick={() => setIndex(id)} />)}
    </Controls>
    <Slides>
      {items.map((item, i) => {
        return (
          <div id={`slide-${i}`}>
            {item}
          </div>
        )
      })}
    </Slides>
    <div ref={endRef} />
  </Slider>
}

const useCyclicIndex = (items) => {
    const [index, setIndex] = useState(0);

    const next = useCallback(() => {
        setIndex(prevIndex => (prevIndex + 1) % items.length);
    }, [items.length]);

    return [index, next, setIndex];
}

const useCycle = (visible, index, next) => {
  useEffect(() => {
    if (visible) {
      const interval = setInterval(() => next(), 10000)

      return () => { clearInterval(interval) }
    }
  })

  useEffect(() => {
    if (visible) {
      const element = document.getElementById(`slide-${index}`)
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
    }
  }, [index])
}

const useVisible = () => {
  const [isVisible, setIsVisible] = useState(false);
  const endOfBlockRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsVisible(entry.isIntersecting);
    }, {
        root: null, 
        rootMargin: '0px',
        threshold: 1.0 
      }
    )

    if (endOfBlockRef.current) {
        observer.observe(endOfBlockRef.current);
    }

    return () => {
      if (endOfBlockRef.current) {
        observer.unobserve(endOfBlockRef.current);
      }
    }
  }, [])

  return [isVisible, endOfBlockRef]
}
