import styled from 'styled-components'
import { DocumentAttach } from '@styled-icons/ionicons-solid/DocumentAttach'
import { Group } from '@styled-icons/boxicons-solid/Group'
import { Gavel } from '@styled-icons/fluentui-system-filled/Gavel'
import { MoneyEuroCircle } from '@styled-icons/remix-line/MoneyEuroCircle'

import { Space } from 'Space'
import { List } from 'List'
import { Title } from 'Title'
import { Item } from 'Item'
import { Em } from 'Em'
import { Text } from 'Text'
import { Block } from 'Block'

const Layout = styled(Block)`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10em;
`

const Right = styled(Space)`

`

export const Purpose = () => (
  <Layout odd>
    <Right data-aos="fade-left">
      <Title invert>Evabia est là pour vous aider, si vous souhaitez</Title>
      <List>
        <Item invert>
          <DocumentAttach size={20} />
          <Text color="black">
            Déléguer la <Em>gestion administrative</Em> de votre entreprise
          </Text>
        </Item>
        <Item invert>
          <Group size={20} />
          <Text color="black">
            Être <Em>accompagné</Em> dans la gestion de votre activité
          </Text>
        </Item>
        <Item invert>
          <Gavel size={20} />
          <Text color="black">
            Bénéficier de conseils éclairés en <Em>matière fiscale</Em>, <Em>juridique</Em> ou en <Em>RH</Em>
          </Text>
        </Item>
        <Item invert>
          <MoneyEuroCircle size={20} />
          <Text color="black">
            Disposer d’une <Em>direction financière</Em> externalisée
          </Text>
        </Item>
      </List>
    </Right>
  </Layout>
)
