import styled from 'styled-components'

export const Base = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;

  display: flex;
  align-items: center;
  grid-gap: .8em;
  transition: color .2s, background .2s, border .2s;
  border-radius: 3px;
  cursor: pointer;
  border: 2px solid ${({ invert }) => `var(--color-${invert ? 'white' : 'black'})`};
  padding: .8em;
  font: var(--font-button);
  color: ${({ invert }) => `var(--color-${invert ? 'white' : 'black'})`};

  &:hover {
    background-color: ${({ invert }) => `var(--color-${invert ? 'white' : 'black'})`};
    color: ${({ invert }) => `var(--color-${invert ? 'black' : 'white'})`};
    border-color: ${({ invert }) => `var(--color-${invert ? 'white' : 'black'})`};
  }
`

export const Borderless = styled(Base)`
  border: none;
  background: none;
  opacity: .7;

  &:hover {
    opacity: 1;
    color: ${({ invert }) => `var(--color-${invert ? 'white' : 'black'})`};
    background: transparent;
  }
`

export const Accent = styled(Base)`
  border-color: var(--color-orange);
  background: none;
  color: var(--color-orange);
  font: var(--font-button);

  &:hover {
    background-color: var(--color-orange);
    color: var(--color-grey);
    border-color: var(--color-orange);
  }
`

export const Filled = styled(Base)`
  background-color: ${({ invert }) => `var(--color-${invert ? 'white' : 'black'})`};
  color: ${({ invert }) => `var(--color-${invert ? 'black' : 'white'})`};
  border-color: ${({ invert }) => `var(--color-${invert ? 'white' : 'black'})`};
`

export const Button = ({ variant, ...props }) => (
  variant === 'borderless' ? <Borderless {...props}/> :
  variant === 'accent' ? <Accent {...props} /> : 
  variant === 'filled' ? <Filled {...props} /> : 
  <Base {...props}/>
)
