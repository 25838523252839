import styled from 'styled-components'

import { Phone } from '@styled-icons/material/Phone'
import { Map } from '@styled-icons/material/Map'
import { Email } from '@styled-icons/material/Email'

import { List } from 'List'
import { Item } from 'Item'
import { Block } from 'Block'
import { Text } from 'Text'

const Layout = styled(Block)` 
  display: flex;
  justify-content: space-between;
  padding: 4em;
  background: var(--color-black);
  color: var(--color-white);
`

const Contact = styled(List)`
  opacity: .7;
`

const SmallText = styled(Text)`
  font: var(--font-small);
`

const Copyright = styled(SmallText)`
  justify-self: end;
`

export const Footer = () => (
  <Layout>
    <Contact>
      <Item>
        <Map size={18} />
        <SmallText>
          12 bis route nationale 113 33640 Beautiran
        </SmallText>
      </Item>
      <Item>
        <Phone size={18} />
        <SmallText>
          06 03 16 49 61
        </SmallText>
      </Item>
      <Item>
        <Email size={18} />
        <SmallText>
          contact@evabia.fr
        </SmallText>
      </Item>
    </Contact>
    <Copyright>
      Copyright © 2024 Evabia 
    </Copyright>
  </Layout>
)
