import styled from 'styled-components'

import { Title } from 'Title'
import { Subtitle } from 'Subtitle'
import { Block } from 'Block'
import silae from 'silae_logo.jpeg'
import myunisoft from 'logo_myunisoft.png'
import limpyd from 'limpyd.svg'
import powerbi from 'powerbi.svg'

import { Tool } from './Tool'
import { useContext } from 'Context'

const Layout = styled(Block)`
  display: flex;
  flex-direction: column;
  grid-gap: 5em;
  align-items: center;
`

const ToolsLayout = styled.div`
  display: grid;
  grid-gap: 7em;
  grid-template-columns: repeat(2, 1fr);
  @media screen and (width <= 900px) {
    grid-gap: 2em;
    grid-template-columns: repeat(1, 1fr);
  }
`

const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Tools = () => {
  const { ref2 } = useContext()

  return <Layout odd ref={ref2}>
    <Head data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-duration="600">
      <Title invert>
        Les outils 
      </Title>
      <Subtitle invert>
        Pour mieux vous accompagner
      </Subtitle>
    </Head>
    <ToolsLayout data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
      <Tool>
        <Tool.Media invert>
          <img src={myunisoft} />
        </Tool.Media>
        <Tool.Label>Myunisoft</Tool.Label>
        <Tool.Description>pour une tenue digitalisée de la comptabilité</Tool.Description>
      </Tool>
      <Tool>
        <Tool.Media invert>
          <img src={limpyd} />
        </Tool.Media>
        <Tool.Label>Limpyd</Tool.Label>
        <Tool.Description>pour générer des factures rapidement en conformité avec les dispositions de la facture électronique</Tool.Description>
      </Tool>
      <Tool>
        <Tool.Media>
          <img src={silae} />
        </Tool.Media>
        <Tool.Label>Silae</Tool.Label>
        <Tool.Description>pour la gestion de la paie</Tool.Description>
      </Tool>
      <Tool>
        <Tool.Media>
          <img src={powerbi} />
        </Tool.Media>
        <Tool.Label>Power BI</Tool.Label>
        <Tool.Description>pour les Dashboards</Tool.Description>
      </Tool>
    </ToolsLayout>
  </Layout>
}
