import styled from 'styled-components'

export const Title = styled.span`
  font: var(--font-title);
  color: ${({ invert }) => `var(--color-${invert ? 'black' : 'white'})`};
  text-transform: uppercase;
  @media screen and (width <= 900px) {
    text-transform: none;
  }
`
