import { useEffect } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { Close } from '@styled-icons/evil/Close'

const Layout = styled.div`
  display: ;
  position: fixed;
  inset: 0;
  z-index: 1000;
  background: var(--color-white);
  padding: 10em;
`

const CloseFullscreen = styled(Close)`
  position: fixed;
  top: 2em;
  right: 2em;
  cursor: pointer;
`

export const Fullscreen = ({ open, onClose, children }) => {
  useEffect(() => {
    document.querySelector('body').style.overflow = open ? 'hidden' : 'visible'
  }, [open])
  const handleKeyDown = event => {
    if (event.keyCode === 27) {
      onClose()
      event.stopPropagation()
    }
  }

  return open && createPortal(<Layout
    onKeyDown={handleKeyDown}
  >
    <CloseFullscreen size={40} onClick={onClose} />
    {children}
  </Layout>, document.body)
}
