import { createContext, useContext as useCtx, useRef } from 'react'

export const Context = createContext({})

export const Provider = ({ children }) => {
  const ref = useRef()
  const ref2 = useRef()

  return <Context.Provider value={{ ref, ref2 }}>
    {children}
  </Context.Provider>
}

export const useContext = () => useCtx(Context)
