import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { Pagination, Navigation, HashNavigation } from 'swiper/modules'

import { Carousel } from 'Carousel'
import { Title } from 'Title'
import { Text } from 'Text'
import { Block } from 'Block'

import ordreEc from './ordreec.svg'

const Layout = styled(Block)`
  display: flex;
  grid-gap: 2em;
  flex-direction: column;
  align-items: center;
  padding-top: 5em;

  .swiper {
    width: 100%;
    height: 100%;
    padding: 2em;
  }

  @media (width <= 900px ) {
    .swiper {
      width: 80vw;
      height: 100%;
      padding: 0;
    }

    padding-inline: 0;
  }


  .swiper-slide {
    text-align: center;
    background: transparent;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide section {
    padding: 0 70px 0 70px;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Section = styled.section` 
  display: flex;
  flex-direction: column;
  grid-gap: 2em;
`

const CenteredText = styled(Text)`
  text-align: center;
`

const StyledTitle = styled(Title)`
  color: var(--color-white);
`

const Logo = styled.img`
  height: 150px;
`

export const Counseling = () => (
  <Layout disableShadow>
    <Swiper
      spaceBetween={30}
      hashNavigation={{
        watchState: true,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Navigation, HashNavigation]}
      className="mySwiper"
    >
      <SwiperSlide data-hash="slide1">
        <Section>
          <StyledTitle>
            Conseil en fiscalité
          </StyledTitle>
          <Text>
            En tant qu'expert en fiscalité, je vous aide à naviguer dans le complexe système fiscal français, en vous offrant des conseils personnalisés pour optimiser votre situation fiscale.
          </Text>
        </Section>
      </SwiperSlide>
      <SwiperSlide data-hash="slide2">

      <Section>
        <StyledTitle>
          Conseil en gestion
        </StyledTitle>
        <Text>
          Grâce à nos outils digitaux avancés nous vous fournissons des analyses précises de votre performance financière pour vous aider dans la gestion de votre activité. Nous pouvons également vous proposer des tableaux de bord sur-mesure mettant en évidence les indicateurs clés nécessaires au pilotage de votre entreprise.
        </Text>
      </Section>
        </SwiperSlide>
        <SwiperSlide data-hash="slide3">

      <Section>
        <StyledTitle>
          Conseil pour créateurs d'entreprise
        </StyledTitle>
        <Text>
          Vous êtes au début de votre aventure entrepreneuriale ? Je suis là pour vous accompagner dans cette étape cruciale. De l'élaboration de votre business plan à la recherche de financements, en passant par les choix juridiques et fiscaux, je mets à votre service mon expertise et des outils digitaux innovants pour vous aider à poser des bases solides pour votre projet.
        </Text>
      </Section>
        </SwiperSlide>
        <SwiperSlide data-hash="slide4">

      <Section>
        <StyledTitle>
          Conseil pour direction financière externalisée
        </StyledTitle>
        <Text>
          Pour les entreprises ne disposant pas d'une direction financière interne, j'offre une solution complète d'externalisation. 
          En tant que votre directeur financier à distance, je vous apporte un soutien constant dans la gestion financière de votre entreprise.
          L'objectif ? Vous permettre de vous concentrer sur votre cœur de métier, tout en ayant l'assurance d'une gestion financière optimale.
        </Text>
      </Section>
        </SwiperSlide>
        <SwiperSlide data-hash="slide5">

      <Section>
        <StyledTitle>
          La digitalisation au service de votre entreprise
        </StyledTitle>
        <Text>
          Basé à Toulouse et Bordeaux, je suis convaincu que la digitalisation est un levier essentiel pour l'accompagnement efficace des dirigeants, où qu'ils soient. L'intégration de l'intelligence artificielle et des outils digitaux dans mes services me permet de vous offrir une expertise comptable de pointe, flexible et adaptée à vos besoins spécifiques.
        </Text>
      </Section>
        </SwiperSlide>
      </Swiper>
    <Section>
      <CenteredText>
        N'hésitez pas à me contacter pour discuter de la manière dont je peux vous aider à atteindre vos objectifs d'entreprise. Bienvenue dans l'ère de la comptabilité 4.0.
      </CenteredText>
    </Section>
    <Logo src={ordreEc} />
  </Layout>
)
