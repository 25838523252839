import styled from 'styled-components'

const Layout = styled.a`
  font: var(--font-body);
  color: var(--color-black);
`

export const Label = ({ link, children }) => (
  <Layout href={link} target="_blank">{children}</Layout>
)
