import { useState } from 'react'
import styled from 'styled-components'
import { LogInCircle } from '@styled-icons/boxicons-regular/LogInCircle'

import { Button } from 'Button'
import { Fullscreen } from 'Fullscreen'

import silae from 'silae_logo.jpeg'
import myunisoft from 'logo_myunisoft.png'
import limpyd from 'limpyd.svg'
import { Tool } from '../Tools/Tool/Tool.jsx'

const ToolsLayout = styled.div`
  display: grid;
  grid-gap: 4em;
  grid-template-columns: repeat(3, 1fr);
`

export const Login = ({ ...props }) => { 
  const [open, setOpen] = useState(false)

  return <>
    <Button onClick={() => window.open('https://evabia.welyb.fr/signin', '_blank').focus()} {...props}>
      <LogInCircle size={20} />
      Connexion
    </Button>
    {/* <Fullscreen open={open} onClose={() => setOpen(false)}> */}
    {/*   <ToolsLayout data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600"> */}
    {/*     <Tool> */}
    {/*       <Tool.Media invert> */}
    {/*         <img src={myunisoft} /> */}
    {/*       </Tool.Media> */}
    {/*       <Tool.Description>Comptabilité</Tool.Description> */}
    {/*       <Button onClick={() => window.open('https://app.myunisoft.fr/', '_blank').focus()}> */}
    {/*         <LogInCircle size={20} /> */}
    {/*         Se connecter */}
    {/*       </Button> */}
    {/*     </Tool> */}
    {/*     <Tool> */}
    {/*       <Tool.Media invert> */}
    {/*         <img src={limpyd} /> */}
    {/*       </Tool.Media> */}
    {/*       <Tool.Description>Facturation</Tool.Description> */}
    {/*       <Button onClick={() => window.open('https://app.limpyd.fr/', '_blank').focus()}> */}
    {/*         <LogInCircle size={20} /> */}
    {/*         Se connecter */}
    {/*       </Button> */}
    {/*     </Tool> */}
    {/*     <Tool> */}
    {/*       <Tool.Media> */}
    {/*         <img src={silae} /> */}
    {/*       </Tool.Media> */}
    {/*       <Tool.Description>Paie</Tool.Description> */}
    {/*       <Button onClick={() => window.open('https://c2g.silae.fr/silae/', '_blank').focus()}> */}
    {/*         <LogInCircle size={20} /> */}
    {/*         Se connecter */}
    {/*       </Button> */}
    {/*     </Tool> */}
    {/*   </ToolsLayout> */}
    {/* </Fullscreen> */}
  </>
}
