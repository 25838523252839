import { useLayoutEffect } from 'react'
import './App.css'
import 'aos/dist/aos.css';
import AOS from 'aos/dist/aos.js';

import { Fonts } from './Fonts'
import { Colors } from './Colors'
import { Header } from './Header'
import { Layout } from './Layout'
import { Home } from './Home'
import { Perks } from './Perks'
import { Tools } from './Tools'
import { Counseling } from './Counseling'
import { Purpose } from './Purpose'
import { Footer } from './Footer'
import { Divider } from './Divider'
import { Provider } from 'Context'

export const App = () => {
  useLayoutEffect(() => {
    AOS.init({ once: true })
  }, [])

  return <Provider>
    <Fonts />
    <Colors />
    <Layout>
      <Header />
      <Home />
      <Purpose />
      <Divider />
      <Tools />
      <Perks />
      <Counseling />
      <Footer />
    </Layout>
  </Provider>
}
