import styled from 'styled-components'
import { CheckCircle } from '@styled-icons/material-rounded/CheckCircle'

import { Space } from 'Space'
import { Item } from 'Item'
import { List } from 'List'
import { Em } from 'Em'
import { Title } from 'Title'
import { Text } from 'Text'
import { Block } from 'Block'
import { useContext } from 'Context'

const Layout = styled(Block)`
  padding-bottom: 5em;
`

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 2em;
`

const Check = styled(CheckCircle)`
  color: var(--color-white);
`

export const Perks = () => {
  return <Layout>
    <Center data-aos="fade-left">
      <Space>
        <Title>
          Evabia vous garantit
        </Title>
        <List>
          <Item>
            <Check size={25}/>
            <Text>
              <Em>Réactivité</Em> (réponse en 48 heures)
            </Text>
          </Item>
          <Item>
            <Check size={25}/>
            <Text>
              Des missions adaptées à <Em>vos besoins</Em> et <Em>sur-mesure</Em>
            </Text>
          </Item>
          <Item>
            <Check size={25}/>
            <Text>
              Une comptabilité 2.0 où <Em>l'humain</Em> reste au centre de l’équation
            </Text>
          </Item>
        </List>
      </Space>
    </Center>
  </Layout>
}
