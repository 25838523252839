import styled from 'styled-components'

import { Label } from './Label'
import { Description } from './Description'

export const Tool = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1em;
  text-align: center;
`

const Media = styled.div`
  & > img {
    height: 150px;
    @media screen and (width <= 900px) {
      max-height: 75px;
    }

    ${({ invert }) => invert && 'filter: invert(100%);'}
  }
`

Tool.Media = Media
Tool.Description = Description
Tool.Label = Label

