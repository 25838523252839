import styled from 'styled-components'

import { Actions } from './Actions'

const Layout = styled.nav` 
  list-style-type: none;
  position: fixed;
  top: 0;
  right: 0;
  padding: 1em 4em 1em 4em;
  background-color: transparent;
  display: grid;
  grid-template-columns: 1fr auto;
  height: min-content;
  z-index: 100;

  @media (width <= 900px) {
    left: 0;
    right: 0;
    padding: 0;

    & button {
      border-radius: 0;
      border: 0;
    }
  }
`

const Blank = styled.div``

export const Header = () => (
  <Layout>
    <Actions/ >
  </Layout>
)
