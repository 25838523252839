import styled from 'styled-components'

export const Block = styled.div`
  @media screen and (width <= 900px) {
    padding-inline: 2em;
    padding-block: 4em;
  }

  padding: 10em;
  background-color: ${({ odd }) => odd ? 'var(--color-white)' : 'var(--color-middle-blue);'}
  ${({ disableShadow }) => !disableShadow && 'box-shadow: 0px -8px 25px -14px rgba(0,0,0,0.75);'}
`
